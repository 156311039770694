import mime from "mime-types";

type Props = {
  src: string;
  alt: string;
  className?: string;
  [key: string]: any;
};

export function Image({ src, alt, className, ...props }: Props) {
  const path = src.replace(/\.[^/.]+$/, "");
  const type = mime.lookup(src) || "image/jpeg";
  const extension = mime.extension(type);

  return (
    <picture>
      <source srcSet={`${path}.avif`} type="image/avif" />
      <source srcSet={`${path}.webp`} type="image/webp" />
      <source srcSet={`${path}.${extension}`} type={type} />
      <img
        src={src}
        alt={alt}
        loading="lazy"
        className={className}
        {...props}
      />
    </picture>
  );
}
